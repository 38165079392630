import Link from 'next/link'
import ArrowCircle from '../Atoms/ArrowCircle'
import React from 'react'

export default React.memo(function More({
  text,
  href,
  type,
  target,
  language,
}: {
  text: string
  href: string
  type?: 'button' | 'text'
  target?: string
  language?: 'en' | 'jp'
}) {
  return type === 'text' ? (
    <div className="inline-block">
      <Link href={href} className="flex items-center group" target={target}>
        <ArrowCircle size="lg" />
        <span
          className={`${language == 'jp' ? 'font-notosans_jp font-bold' : 'font-avenirNextBold'} text-[14px] md:text-[16px] tracking-[.08em] leading-none ml-3 whitespace-nowrap group-hover:text-blue_400 transition-colors duration-300`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Link>
    </div>
  ) : (
    <div className="inline-block">
      <Link
        href={href}
        className="flex items-center rounded-[40px] border border-darkgrey_100 h-[48px] md:h-[56px] px-5 md:px-6"
      >
        <span className="material-symbols-outlined text-blue_400 text-[20px] md:text-[22px]">
          east
        </span>
        <span
          className={`font-avenirNextBold text-[12px] md:text-[14px] leading-none ml-2 whitespace-nowrap`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Link>
    </div>
  )
})
